import COLORS from 'const/colors';

const ChevronLeftBold = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.3333 1.33341L4.66659 8.00008L11.3333 14.6667"
      stroke={COLORS.PURPS.LEVEL_3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ChevronLeftBold;
