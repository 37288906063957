const HorizontalLine = () => (
  <svg
    width="221"
    height="2"
    viewBox="0 0 221 2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      opacity="0.3"
      width="221"
      height="2"
      rx="1"
      fill="url(#paint0_linear_184_10201)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_184_10201"
        x1="0"
        y1="1"
        x2="221"
        y2="1.00002"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="1" stopColor="white" />
      </linearGradient>
    </defs>
  </svg>
);
export default HorizontalLine;
